import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-study-optimizing-input',
  templateUrl: './case-study-optimizing-input.component.html',
  styleUrls: ['./case-study-optimizing-input.component.scss']
})
export class CaseStudyOptimizingInputComponent implements OnInit {

  showLarge = false;
  showMedium = false;
  showSmall = false;

  ngOnInit() {
    this.checkForWidth();
    
    window.onresize = () => {
      this.checkForWidth();
    }
  }

  checkForWidth() {
    this.showLarge = (window.innerWidth >= 850);
    this.showMedium = (window.innerWidth < 850 && window.innerWidth > 550);
    this.showSmall = (window.innerWidth <= 550);
  }

  constructor(private viewportScroller: ViewportScroller) {
  }

  public scrollToSection(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
