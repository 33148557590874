<div class="banner enrolling cover">
    <img srcset="./assets/img/case-study-enrolling/enrolling-cover.png 1x,
                 ./assets/img/case-study-enrolling/enrolling-cover@2x.png 2x"
         src="./assets/img/case-study-enrolling/enrolling-cover.png" />
</div>

<h2>Enrollment process</h2>
<div class="text-wrapper">
    <p>The Client: <a href="https://www.samsamkring.nl/" target="_blank" alt="Website of the SamSam">SamSam</a></p>
</div>

<div class="side-by-side">
    <span>
        <h5>The challenge</h5>
        <p>Streamlining the enrolling process for potential joiners of the disability insurance alternative. How can we make sure people don’t drop out of the enrollment process feeling intimidated or inhibited? We want the process to be as transparent and straightforward as possible with the boundaries set, while also presenting the right brand.  After enrolling we want the joiners to have insight in their savings and status.</p>
    </span>
    <span>
        <div class="highlight">
            <h5>My role</h5>
            <p>
                <strong>Customer journey mapping, user research, information design, visual design</strong><br />
                I was responsible for understanding the existing process, improving on it where possible, and creating a recognizable and easy to use user interface. I produced all the deliverables.</p>
        </div>
    </span>
</div>

<div class="text-wrapper">
    <h5>The solution</h5>
    <p>By mapping the whole enrollment process and figuring out where joiners struggle I was able to pinpoint potential points of improvement and streamline the steps necessary. By using recognizable patterns and easy-to-use interactions I designed a new way of enrolling joiners which made joining a breeze. After enrollment a visual representation of all stats is shown to make it easy for users to get updated quickly and to empower them to change monthly payments and such.</p>
    <span class="image-container">
        <img src="./assets/img/case-study-enrolling/introduction-register.svg" />
        <span class="caption">An example of the contact points from first introduction to enrollment.</span>
    </span>
</div>

<div class="banner">
    <div class="banner-wrapper">
        <h5>Summary</h5>
        <p>By talking to product owners and users the enrollment process was mapped. With this map steps were examined and changed or cut where needed to ensure a smooth process. After talking over the first ideas I started out drawing an overview of how the new process could be done. This was cut into little pieces which in itself were smaller features to be realized in order of priority. By creating these smaller features and turning them into low-fidelity designs the whole process was easy to communicate to the stakeholders.</p>

        <h5>Conclusion</h5>
        <p>A customer journey map of the complete enrollment process, showing just how many steps could be improved. With this map a number of smaller sub-processes were determined and worked out in small low-fidelity design flows. These flows show what kind of user interface could be used and give an insight into what needs to be built and what can be expected. For visual reference one of the steps was worked out as a high fidelity design. This high fidelity design shows off the look and feel of the eventual product. To make matters more complicated, the base design is whitelabeled because the end-product should be usable for multiple brands.</p>

        <div class="button-container">
            <button mat-flat-button select [matMenuTriggerFor]="scrollto" alt="More about this case-study">More about this</button>
            <mat-menu #scrollto="matMenu">
                <button mat-menu-item (click)="scrollToSection('first-steps')">First steps</button>
                <button mat-menu-item (click)="scrollToSection('low-fidelity')">Low fidelity</button>
                <button mat-menu-item (click)="scrollToSection('information-design')">Information design</button>
                <button mat-menu-item (click)="scrollToSection('results')">Results</button>
            </mat-menu>
            <button mat-flat-button select [matMenuTriggerFor]="othercases" alt="View other case studies">View another case study</button>
            <mat-menu #othercases="matMenu">
                <button mat-menu-item routerLink="/blauwdruk" routerLinkActive="active" alt="Blauwdruk">Blauwdruk</button>
                <button mat-menu-item routerLink="/dark-theme" routerLinkActive="active" alt="Dark theme">Dark theme</button>
                <button mat-menu-item routerLink="/optimizing-input" routerLinkActive="active" alt="Optimizing input">Optimizing input</button>
            </mat-menu>
        </div>
    </div>
</div>

<a class="anchor" id="first-steps"></a>
<div class="text-wrapper">
    <h3>First steps</h3>
    <h5>The customer journey</h5>
    <p>After SamSam talks with potential joiners and they decide they want to join, a first interview is done. This interview tries to answer questions the joiners still have and to get to know the joiner and their financial situation. Getting to know the process as it is done is the first step. By enquiring how it is experienced by different users is the second. Doing this a complete picture is constructed which I visualized in the image below. It is a part of the whole process which concentrates on the steps after choosing to join. This is the part that could potentially support joiners by providing step-by-step instructions and an easy to use application. The application will be both mobile and web-based, so it can support joiners that are on the go as well as behind a desktop.</p>
    <span class="image-container">
        <img src="./assets/img/case-study-enrolling/overview-app-content.svg" />
        <span class="caption">Showing the steps in a minimalistic way to provide overview of the digital side of the process.</span>
    </span>
    <p>In the image above there’s a focus on the app that needs to provide users with support both before and after completing the enrollment. The left square shows the step-by-step guidance for enrolling joiners to help them complete the process with minimum effort and maximum support. The right square is focused on the final steps and giving users the tools to configure their information, payments and other parameters.</p>
</div>

<a class="anchor" id="low-fidelity"></a>
<div class="text-wrapper">
    <h5>Low fidelity flows</h5>
    <p>By breaking up the customer journey in smaller pieces it’s easier to focus on seperate parts of the proces, the first time a user logs in for example (as shown below). This way use cases can be designed in low fidelity, but with enough detail to show off the main idea and steps. The first flows can also be used for discussion and to make sure the designer, developer and client are on the same wavelength.</p>
    <span class="image-container">
        <img src="./assets/img/case-study-enrolling/first-time-login.svg" />
        <span class="caption">Showing the steps of login in for the first time in the app.</span>
    </span>
    <p>These low fidelity flows are a perfect way of showing the big picture without getting into specifics. Using this as a tool to show off all ideas in an early stage, before starting on development, saves a lot of precious time. It’s also easy to make changes which would take more effort when it concerns high fidelity designs.</p>
    <span class="image-container">
        <img src="./assets/img/case-study-enrolling/changing-status.svg" />
        <span class="caption">Showing the steps for changing the current status of the user to being ill or healthy.</span>
    </span>
    <p>The image above depicts the flow for changing status to being ill for a longer period or changing to being well again. It allows the user to view the number of days they were ill and the amount of money they received during this period. The image below shows the user their current settings for getting paid when being sick over longer periods. They enable the user to change these at any time to make sure they are aware and in control of their own payments and surcharges.</p>
    <span class="image-container">
        <img src="./assets/img/case-study-enrolling/money-details.svg" />
        <span class="caption">Showing the construction of the SamSam fund.</span>
    </span>
</div>

<a class="anchor" id="information-design"></a>
<div class="text-wrapper">
    <h5>Information design</h5>
    <p>Users that have registered already have a need for clear and accurate information about their current status. A lot of information is available and should be made available in an easy to read way so users can get an overview in an instance. Different kinds of information are displayed differently to accommodate the optimal way of relaying it to the user. For example; users pay a specific amount per month until their buffer is full, therefore it’s good to know how much is paid already and how much still needs to be paid. By showing a circle diagram that fills up based on the amount paid a user can see how far they are along in a blink of an eye.</p>
    <span class="image-container">
        <img src="./assets/img/case-study-enrolling/example-cards.svg" alt="">
        <span class="caption">A number of cards and different iterations of the information design of these cards.</span>
    </span>
    <p>Another example would be the status card which shows the user right away if everything is in order. It’s also the first card users see when something is amiss, telling them what is wrong and how to fix it. For example when a payment wasn’t received.</p>
</div>

<a class="anchor" id="results"></a>
<div class="banner">
    <div class="banner-wrapper">
        <h5>Results</h5>
        <p>Based on feedback received a first high fidelity visual design is produced. This will show the details and makes it clear what the final product will look like. It also shows the potential for alternative themes for companies that wish to show their own corporate identity. This way users that get to use SamSam through their own company can see it in a familiar manner.</p>
        <p>Furthermore SamSam has been provided with invaluable feedback about how their users interact with their current product. Potential pitfalls and ways to overcome them have been explored and a first high fidelity has been designed based on these findings. As a cherry on top we also showed the added value of a dark-theme which has been added in the final design as well.</p>        
        <span class="image-container">
            <img src="./assets/img/case-study-enrolling/example-result.svg" alt="">
            <span class="caption">An example of one of the visuals that a user would get to see and interact with.</span>
        </span>
    </div>
</div>

<more-cases currentCase="enrolling"></more-cases>