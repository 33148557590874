<div class="banner optimizing-input cover">
    <img srcset="./assets/img/case-study-optimizing-input/optimizing-input-cover.jpg 1x,
                 ./assets/img/case-study-optimizing-input/optimizing-input-cover.jpg 2x"
         src="./assets/img/case-study-optimizing-input/optimizing-input-cover.jpg" />
</div>

<h2>Optimizing input</h2>
<div class="text-wrapper">
    <p>The Client: <a href="https://www.rijkswaterstaat.nl/" target="_blank" alt="Website of the Department of Waterways and Public Works">Dutch Department of Waterways and Public Works</a></p>
</div>

<div class="side-by-side">
    <span>
        <h5>The challenge</h5>
        <p>Road inspectors need to go to specific locations on or next to the national highways and provincial roads. These locations are marked by hectometre markers, small signs with specific numbers indicating what road, what direction and what part of the road they’re on. Unfortunately all navigation software the road inspectors used did not have a means to directly input such a location, the closest they got was using GPS coordinates. The challenge was to provide the inspectors with an interface to input the hectometre marker easily to navigate to.</p>
    </span>
    <span>
        <div class="highlight">
            <h5>My role</h5>
            <p>
                <strong>Interaction design, visual design, user research, usability testing</strong><br />
                As part of a small team I was responsible for the interface design, usability testing and building the application front-end. The database and GPS to BPS translations were done by a colleague.</p>
        </div>
    </span>
</div>

<div class="text-wrapper">
    <h5>The solution</h5>
    <p>To assist road inspectors we created an app to be used on their mobile device. It allows inspectors to input the hectometre in a recognizable way. The UI elements were made especially large to prevent inspectors from pressing the wrong button and to ensure maximum readability. To further prevent wrong input the app responds to input by disabling numbers dynamically (more details). After inputting the necessary data a list of hectometre signs is shown, by picking one the navigation-app of choice starts up and navigates the user to their destination.</p>
    <span class="image-container">
        <img srcset="./assets/img/case-study-optimizing-input/context-image.jpg 1x,
                 ./assets/img/case-study-optimizing-input/context-image.jpg 2x"
         src="./assets/img/case-study-optimizing-input/context-image.jpg" />
        <span class="caption">An example of the contact points from first introduction to enrollment.</span>
    </span>
</div>

<div class="banner">
    <div class="banner-wrapper">
        <h5>End result</h5>
        <p>An easy to use step-by-step application made specifically for the iPads used in the mobile workspace of road-inspectors. Tailor made to suit the needs of our inspectors to navigate easily, efficiently and quickly to the right destination without the hassle of translating a hectometre marker to a GPS-coordinate manually.</p>

        <div class="button-container">
            <button mat-flat-button select [matMenuTriggerFor]="scrollto" alt="More about this case-study">More about this</button>
            <mat-menu #scrollto="matMenu">
                <button mat-menu-item (click)="scrollToSection('hectometre-marker')">The hectometre marker</button>
                <button mat-menu-item (click)="scrollToSection('how-it-works')">How it works</button>
                <button mat-menu-item (click)="scrollToSection('result')">Result</button>
            </mat-menu>
            <button mat-flat-button select [matMenuTriggerFor]="othercases" alt="View other case studies">View another case study</button>
            <mat-menu #othercases="matMenu">
                <button mat-menu-item routerLink="/blauwdruk" routerLinkActive="active" alt="Blauwdruk">Blauwdruk</button>
                <button mat-menu-item routerLink="/dark-theme" routerLinkActive="active" alt="Dark theme">Dark theme</button>
                <button mat-menu-item routerLink="/enrollment-process" routerLinkActive="active" alt="Enrollment process">Enrollment process</button>
            </mat-menu>
        </div>
    </div>
</div>

<a class="anchor" id="hectometre-marker"></a>
<div class="text-wrapper">
    <h5>The hectometre marker</h5>
    <p>More on hectometre markers on <a href="https://www.hmpaal.nl/" alt="More about hectometre markers" target="_blank">hmpaal.nl</a></p>
    <span class="image-container">
        <img *ngIf="showLarge" src="./assets/img/case-study-optimizing-input/uitleg-hmpaal-large.svg"/>
        <img *ngIf="showMedium" src="./assets/img/case-study-optimizing-input/uitleg-hmpaal-medium.svg"/>
        <img *ngIf="showSmall" src="./assets/img/case-study-optimizing-input/uitleg-hmpaal-small.svg"/>
    </span>
</div>

<a class="anchor" id="how-it-works"></a>
<h5>How it works</h5>
<p>When the user starts up the app they are presented with the following screen. This screen consists of a representation of a hectometre marker, a list of possible hectometre markers, the number pad and the current location the user’s at. </p>
<p>The user starts out by inputting the number of the provincial road. These roads can either be N-roads or A-roads. When a number is dialed a list is shown with suggestions of possible roads that start with that number. For example, when “2” is dialed it will show a list of the A2, A20, A200, etc. A-roads will always be shown before N-roads because they are more often used by the inspectors.</p>
<div class="side-by-side">
    <span class="image-container" style="max-width: 30rem;">
        <img srcset="./assets/img/case-study-optimizing-input/step1.jpg 1x,
                     ./assets/img/case-study-optimizing-input/step1@2x.jpg 2x"
             src="./assets/img/case-study-optimizing-input/step1.jpg" />
    </span>
    <span class="image-container" style="max-width: 30rem;">
        <img srcset="./assets/img/case-study-optimizing-input/step2.jpg 1x,
                     ./assets/img/case-study-optimizing-input/step2@2x.jpg 2x"
             src="./assets/img/case-study-optimizing-input/step2.jpg"/>
    </span>
</div>

<p>When a second number is dialed the list with suggestions is updated to reflect the changes, based on the current input all numbers that cannot be dialed are disabled. This way inspectors are prevented from dialing in a wrong number and getting zero suggestions.</p>
<p>When the number of the road is dialed the inspector can select the road from the list of suggestions, this can be done at any point. If a wrong number is dialed, the back-button can be used to remove the numbers one by one.</p>
<div class="side-by-side">
    <span class="image-container" style="max-width: 30rem;">
        <img srcset="./assets/img/case-study-optimizing-input/step3.jpg 1x,
                     ./assets/img/case-study-optimizing-input/step3@2x.jpg 2x"
             src="./assets/img/case-study-optimizing-input/step3.jpg" />
    </span>
    <span class="image-container" style="max-width: 30rem;">
        <img srcset="./assets/img/case-study-optimizing-input/step4.jpg 1x,
                     ./assets/img/case-study-optimizing-input/step4@2x.jpg 2x"
             src="./assets/img/case-study-optimizing-input/step4.jpg"/>
    </span>
</div>

<p>When the road is selected a list of ranges is shown. These ranges show the user all possible numbers that exist on the selected road. The reason this is important has to do with the fact that not all roads have consecutive numbers from start to finish.</p>
<p>When a number is dialed the numbers that cannot be selected will be disabled to prevent wrong input. The app assumes that when only a single number is dialed, it means it’s the whole decimal. For example, when “3” is dialed, the app assumes it’s “3,0” which is a valid input and allows the user to press “enter”.</p>
<div class="side-by-side">
    <span class="image-container" style="max-width: 30rem;">
        <img srcset="./assets/img/case-study-optimizing-input/step5.jpg 1x,
                     ./assets/img/case-study-optimizing-input/step5@2x.jpg 2x"
             src="./assets/img/case-study-optimizing-input/step5.jpg" />
    </span>
    <span class="image-container" style="max-width: 30rem;">
        <img srcset="./assets/img/case-study-optimizing-input/step6.jpg 1x,
                     ./assets/img/case-study-optimizing-input/step6@2x.jpg 2x"
             src="./assets/img/case-study-optimizing-input/step6.jpg"/>
    </span>
</div>

<p>Likewise when the inspector wants to dial “2,3” for example, they dial a “2”, then a comma and then a “3” followed by “enter”. When the comma is dialed all numbers are available for use and can be dialed to form a valid number. If for example the range would go to “2,2” max, it would only allow “0”,”1” and “2” to be active.</p>
<div class="side-by-side">
    <span class="image-container" style="max-width: 30rem;">
        <img srcset="./assets/img/case-study-optimizing-input/step7.jpg 1x,
                     ./assets/img/case-study-optimizing-input/step7@2x.jpg 2x"
             src="./assets/img/case-study-optimizing-input/step7.jpg" />
    </span>
    <span class="image-container" style="max-width: 30rem;">
        <img srcset="./assets/img/case-study-optimizing-input/step8.jpg 1x,
                     ./assets/img/case-study-optimizing-input/step8@2x.jpg 2x"
             src="./assets/img/case-study-optimizing-input/step8.jpg"/>
    </span>
</div>

<p>When the numbers are valid the inspector needs to press the “enter” button to continue to the last screen in which all possible marks will be displayed. When only one marker is the result of the given input the navigation will start. Often input will result in a number of results like the A2 which has an extra designation like "Li"(left), "Re"(right), “n”,”x”, etc.</p>
<p>When finished the navigation is started and will navigate the inspector to the destination.</p>
<div class="side-by-side">
    <span class="image-container" style="max-width: 30rem;">
        <img srcset="./assets/img/case-study-optimizing-input/step9.jpg 1x,
                     ./assets/img/case-study-optimizing-input/step9@2x.jpg 2x"
             src="./assets/img/case-study-optimizing-input/step9.jpg" />
    </span>
    <span class="image-container" style="max-width: 30rem;">
        <img srcset="./assets/img/case-study-optimizing-input/step10.jpg 1x,
                     ./assets/img/case-study-optimizing-input/step10@2x.jpg 2x"
             src="./assets/img/case-study-optimizing-input/step10.jpg" />
    </span>
</div>

<a class="anchor" id="result"></a>
<div class="banner">
    <div class="banner-wrapper">
        <h5>Lessons learned</h5>
        <p>Every project has time constraints, technical constraints, etc. Because of technical constraints the list of suggestions is on the left, instead of on the right. The call-to-action could be more apparent to guide the user into typing and finding the right hectometre marker. The list could visually be more like a list to pick from. Even though these changes would improve the product, the big upside is that after using the app once, inspectors were able to use it without any issues. This means the learnability of the design was enough to compensate for the lack of instant recognizability of the interaction.</p>
        <h5>Result</h5>
        <p>Road inspectors got a new tool to quickly navigate to where they need to be in an easy to use way. Feedback was very positive after first use and adoption went quickly. It perfectly fits into their current mobile workplace, which is an iPad that is docked in their cars.</p>
    </div>
</div>

<more-cases currentCase="optimizing-input"></more-cases>