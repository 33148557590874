<div hex-wrapper *ngFor="let number of [0,1,2]" class="hex-container">
    <div class="hex coral large">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 71">
          <defs/>
          <path d="M56.5 10.7l-14-8.1a19 19 0 00-19 0l-14 8.1A19.3 19.3 0 000 27.4v16.2a19.3 19.3 0 009.5 16.7l14 8.1a19 19 0 0019 0l14-8.1A19.3 19.3 0 0066 43.6V27.4a19.3 19.3 0 00-9.5-16.7z" data-name="hexagon"/>
      </svg>
    </div>
    <div class="hex blue medium">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 71">
        <defs/>
        <path d="M56.5 10.7l-14-8.1a19 19 0 00-19 0l-14 8.1A19.3 19.3 0 000 27.4v16.2a19.3 19.3 0 009.5 16.7l14 8.1a19 19 0 0019 0l14-8.1A19.3 19.3 0 0066 43.6V27.4a19.3 19.3 0 00-9.5-16.7z" data-name="hexagon"/>
      </svg>
    </div>
    <div class="hex dark-blue small">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 71">
        <defs/>
        <path d="M56.5 10.7l-14-8.1a19 19 0 00-19 0l-14 8.1A19.3 19.3 0 000 27.4v16.2a19.3 19.3 0 009.5 16.7l14 8.1a19 19 0 0019 0l14-8.1A19.3 19.3 0 0066 43.6V27.4a19.3 19.3 0 00-9.5-16.7z" data-name="hexagon"/>
      </svg>
    </div>
</div>