<div class="intro">
    <div class="shark-says-hi">
        <span class="text-bubble">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270.9 121.6">
                <defs/>
                <path fill="#bdc7c9" d="M136.2 5.8c-72.7 0-131.6 26-131.6 58C4.6 78.4 17.2 92 38 102.2c0 0-24.1 12.2-37.9 18.9 27-2 58.5-10.6 58.5-10.6a258 258 0 0077.5 11c72.6 0 131.5-25.9 131.5-57.9S208.8 5.8 136.2 5.8z"/>
                <g fill="#fff">
                  <path d="M5.1 119.8c5.6-3 13-7.7 22.1-13.9 9-6 16.4-11.3 16.5-11.4l1.3-1-1.5-.7a77.9 77.9 0 01-24-16.9c-5.8-6.3-8.7-12.9-8.7-19.7 0-3.6.9-7.3 2.6-10.9 1.7-3.5 4.1-7 7.4-10.3 3.2-3.2 7.2-6.4 11.9-9.4 4.6-3 9.9-5.7 15.8-8.2a166 166 0 0119.2-6.8c6.9-2 14.3-3.8 22-5.2 16-3 33.1-4.4 50.7-4.4C157.9 1 175 2.5 191 5.4c7.7 1.4 15.1 3.1 22 5.2a166 166 0 0119.3 6.8c5.8 2.5 11.1 5.3 15.8 8.2 4.6 3 8.6 6.2 11.8 9.4 3.3 3.4 5.8 6.8 7.4 10.3 1.7 3.6 2.6 7.3 2.6 11 0 3.6-.9 7.3-2.6 10.8-1.6 3.5-4.1 7-7.4 10.3a67.4 67.4 0 01-11.8 9.4c-4.7 3-10 5.7-15.8 8.3a166 166 0 01-19.2 6.8 283.4 283.4 0 01-72.7 9.6c-14.1 0-28-1-41.2-2.9a222.8 222.8 0 01-35.3-7.9h-.4l-.4.1A287.1 287.1 0 0121 116.5c-5.8 1.5-11 2.7-15.9 3.3z"/>
                  <path fill="#001e2a" d="M140.4 2c-17.5 0-34.5 1.5-50.5 4.4-7.7 1.4-15 3.1-22 5.1a165 165 0 00-19 6.8c-5.9 2.5-11.1 5.3-15.7 8.2-4.6 2.9-8.5 6-11.7 9.2a37.4 37.4 0 00-7.2 10c-1.6 3.5-2.5 7-2.5 10.5 0 6.5 2.8 13 8.4 19 2.7 3 6.1 6 10 8.8A99 99 0 0044 92l3 1.4-2.7 2a745.2 745.2 0 01-34 22.5 230 230 0 0030.7-9c12.4-4.5 21.6-9 21.7-9l.7-.3.8.2c10.6 3.4 22.5 6 35.2 7.8a290.9 290.9 0 00113.4-6.7 165 165 0 0019-6.8c5.9-2.5 11.1-5.2 15.7-8.1 4.6-3 8.5-6 11.7-9.3 3.2-3.2 5.6-6.6 7.2-10 1.7-3.4 2.5-7 2.5-10.5s-.8-7-2.5-10.4c-1.6-3.5-4-6.8-7.2-10a66.4 66.4 0 00-11.7-9.3c-4.6-3-9.8-5.7-15.6-8.2a165 165 0 00-19.1-6.8c-7-2-14.3-3.7-22-5.1-16-3-33-4.4-50.4-4.4m0-2c72 0 130.5 25.2 130.5 56.2 0 31-58.5 56.2-130.5 56.2-29 0-55.6-4-76.8-10.7 0 0-36.8 17.7-63.6 19.7a442 442 0 0043-27.7C22.5 83.7 9.9 70.6 9.9 56.2 9.8 25.2 68.3 0 140.4 0z"/>
                </g>
              </svg>
        </span>
        <img class="shark-says-hi" [class.hide]="(develops || designs || photographs || prototypes || illustrates)" src="./assets/img/shark-says-hi.svg" alt="shark says hi">
        <img class="shark-overlay shark-develops" [class.show]="develops" src="./assets/img/shark-develops.svg" alt="shark develops">
        <img class="shark-overlay shark-designs" [class.show]="designs" src="./assets/img/shark-designs.svg" alt="shark designs">
        <img class="shark-overlay shark-photographs" [class.show]="photographs" src="./assets/img/shark-photographs.svg" alt="shark photographs">
        <img class="shark-overlay shark-illustrates" [class.show]="illustrates" src="./assets/img/shark-illustrates.svg" alt="shark illustrates">
        <img class="shark-overlay shark-prototypes" [class.show]="prototypes" src="./assets/img/shark-printing.svg" alt="shark protoypes">
    </div>
    <h1>Hello! I'm <strong class="about-me-message" routerLink="/about" routerLinkActive="active" alt="About Chris">Chris van Schayk</strong></h1>
    <h2>I 
        <strong (mouseover)="designs=true" 
                (mouseout)="designs=false">
            design</strong>, 
        <strong (mouseover)="develops=true" 
                (mouseout)="develops=false">
            develop</strong>, 
        <strong (mouseover)="prototypes=true" 
                (mouseout)="prototypes=false">
            prototype</strong>, 
        <strong (mouseover)="photographs=true" 
                (mouseout)="photographs=false">
            photograph</strong> & 
        <strong (mouseover)="illustrates=true" 
                (mouseout)="illustrates=false">
            illustrate<span>*I also do a lot of other things</span>
        </strong>.*
    </h2>
    <p>
        As a 
        <strong matTooltipClass="customTooltip" 
                matTooltip="As a UX-designer I love all aspects of UX and am especially focused on rapid prototyping combined with usability testing and ideation.">
                UX-designer
        </strong>
        /
        <strong matTooltipClass="customTooltip" 
                matTooltip="I'm a front-end developer that loves to fix complex (styling) issues. Using HTML5, CSS3, Angular, Stenciljs and others.">developer
        </strong> 
        hybrid I'm familiar with the 
        <strong matTooltipClass="customTooltip" 
                matTooltip="I'm familiar with the use of margins, paddings, whitespace and such to create a visually appealing design.">visual</strong>, 
        <strong matTooltipClass="customTooltip" 
                matTooltip="With a technical background I'm able to communicate with developers in their terms.">technical</strong>
        and
        <strong matTooltipClass="customTooltip" 
                matTooltip="As a product owner I have experience with implementing scope, prioritizing features and aligning resources with deliverables.">functional</strong> 
        sides of building a solution. My goal is to support developers, designers, users and the business alike as a 
        <strong matTooltipClass="customTooltip" 
                matTooltip="When on the job I feel responsible align all stakeholders, from developers and UX-designers to managers and users">bridge</strong>. I'm an 
        <strong matTooltipClass="customTooltip" 
                matTooltip="I feel responsible for the work I deliver and promises should be kept.">involved</strong>
        person, with 
        <strong matTooltipClass="customTooltip" 
                matTooltip="I'm familiar with communication protocols, architectural design, technical design and have experience with building solutions.">technical know-how</strong>
        and a 
        <strong matTooltipClass="customTooltip" 
                matTooltip="I like to get things done, show results, be concrete regarding subjects and plan of approach.">
            go-getter
        </strong>
        mentality, who meets his deadlines.
    </p>
</div>
<div class="subheader">
    Featured work
</div>
<div class="case-studies">
    <case-study subject="Blauwdruk"
                imageUrl="./assets/img/blauwdruk-logo.svg"
                [tags]="['Visual design','Research','Information design','Development','Structure','Facilitation','Context-aware design']"
                buttonLabel="View case study">
        How to build a design system? Blauwdruk is the new technical design system of the Dutch Police, based on <a href="https://storybook.js.org/" target="_blank" alt="More about Storybook" rel="noreferrer">Storybook</a>.
    </case-study>

    <case-study subject="Dark theme"
                imageUrl="./assets/img/dark-vs-light-cover-picture-small.jpg"
                [tags]="['Research', 'Context-aware design', 'Testing']"
                [coverImage]=true
                buttonLabel="View research">
        Apps on mobile devices give users the ability to use it anytime and anywhere, including at night. This presents Police officers with flexibility but also exposes them during stakeouts for example.
    </case-study>

    <case-study subject="Enrollment process"
                imageUrl="./assets/img/enrolling.jpg"
                [tags]="['Customer journey', 'User research', 'Information design', 'Visual design']"
                [coverImage]=true
                buttonLabel="View case study">
        SamSam offers entrepreneurs an alternative to the often costly disability insurance. They asked me to take a look at how their enrollment process could be optimized to ensure users can enroll effortlessly.
    </case-study>

    <case-study subject="Optimizing input"
                imageUrl="./assets/img/gtbps-cover.jpg"
                [tags]="['Customer journey', 'User research', 'Usability testing', 'Interaction design', 'Visual design', 'App development']"
                [coverImage]=true
                buttonLabel="View case study">
        When you need to navigate to specific locations along the national highways you can't use an address and inputting a GPS-coordinate is not an easy task. I looked at an easy way for road inspectors to input these specific locations.
    </case-study>
</div>