import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {

  develops = false;
  designs = false;
  prototypes = false;
  illustrates = false;
  photographs = false;
}
