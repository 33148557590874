<div class="case-study-wrapper">
    <div class="image-wrapper" [class.cover-image]="coverImage" 
                               [routerLink]="'/' + navigationParameter" 
                               routerLinkActive="active">
        <img src="{{ imageUrl }}" alt="image of {{ subject }}"/>
    </div>
    <div class="info">
        <h2>{{ subject }}</h2>
        <p>
            <ng-content></ng-content>
        </p>
        <div class="tags">
            <tag-chip *ngFor="let tag of tags">{{ tag }}</tag-chip>
        </div>
        <button mat-flat-button 
                [routerLink]="'/' + navigationParameter" 
                routerLinkActive="active">{{ buttonLabel }}</button>
    </div>
</div>