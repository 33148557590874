<div class="side-by-side">
    <span>
        <h2>Chris van Schayk</h2>
        <p>I’m ambitious, creative, enthusiastic and have a can-do mentality. I love working on a project from start to finish, helping with conceptualizing and bringing it to life. I thrive when prototyping and love seeing designs become alive and interactive. I’m a capable researcher, have a lot of experience in usability testing, know how to solve problems and to keep an eye on the bigger picture. I’m solution driven which shows in both my UX-achievements as it does in my development achievements. I’m communicative, work well in teams and am also capable of working on my own.</p>
    </span>
    <span>
        <div class="portrait">
            <div class="hex blue">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 71">
                    <defs/>
                    <path d="M56.5 10.7l-14-8.1a19 19 0 00-19 0l-14 8.1A19.3 19.3 0 000 27.4v16.2a19.3 19.3 0 009.5 16.7l14 8.1a19 19 0 0019 0l14-8.1A19.3 19.3 0 0066 43.6V27.4a19.3 19.3 0 00-9.5-16.7z" data-name="hexagon"/>
                </svg>
            </div>
            <div class="hex dark-blue">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 71">
                    <defs/>
                    <path d="M56.5 10.7l-14-8.1a19 19 0 00-19 0l-14 8.1A19.3 19.3 0 000 27.4v16.2a19.3 19.3 0 009.5 16.7l14 8.1a19 19 0 0019 0l14-8.1A19.3 19.3 0 0066 43.6V27.4a19.3 19.3 0 00-9.5-16.7z" data-name="hexagon"/>
                </svg>
            </div>
            <div class="hex coral">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 71">
                    <defs/>
                    <path d="M56.5 10.7l-14-8.1a19 19 0 00-19 0l-14 8.1A19.3 19.3 0 000 27.4v16.2a19.3 19.3 0 009.5 16.7l14 8.1a19 19 0 0019 0l14-8.1A19.3 19.3 0 0066 43.6V27.4a19.3 19.3 0 00-9.5-16.7z" data-name="hexagon"/>
                </svg>
            </div>
            <div class="hex broken-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 71">
                    <defs/>
                    <path d="M56.5 10.7l-14-8.1a19 19 0 00-19 0l-14 8.1A19.3 19.3 0 000 27.4v16.2a19.3 19.3 0 009.5 16.7l14 8.1a19 19 0 0019 0l14-8.1A19.3 19.3 0 0066 43.6V27.4a19.3 19.3 0 00-9.5-16.7z" data-name="hexagon"/>
                </svg>
            </div>
            <div class="mask">
                <img srcset="./assets/img/portrait-of-chris.jpg 1x, ./assets/img/portrait-of-chris@2x.jpg 2x"
                     src="./assets/img/portrait-of-chris.jpg" alt="Portrait of Chris" />
            </div>
        </div>
    </span>
</div>

<div class="text-with-shark first">
    <span class="image-container">
        <img src="./assets/img/shark-designs.svg" />
    </span>
    <p>I love combining ux-design with software development, taking the best of both worlds and being a bridge between the two disciplines.</p>
    <span class="image-container">
        <img src="./assets/img/shark-develops.svg" />
    </span>
</div>

<div class="text-wrapper">
    <h2>About me personally</h2>
</div>
<div class="text-with-shark">
    <span class="image-container">
        <img src="./assets/img/shark-printing.svg" />
    </span>
    <p>In my spare time I’m either out and about with a camera in my hands or inside tinkering on projects. I have always loved puzzles and figuring out how things work. I used to make anything out of a bit of cardboard, scissors, some glue and split pins, making working transformers from just that. These days I've upgraded to a 3D printer, hot glue, sandpaper and specialized paint.</p>
</div>

<div class="text-with-shark">
    <p>My main passion though is without a doubt photography. I just love being out and about and admiring the world around me. I enjoy walking through forests, fields and such and especially when there’s a lot of wildlife to be discovered. I have a wide selection of specialized lenses for portraits, wildlife, product and street photography. It’s always exciting to get a different perspective on things (with a new lens). A part of my collection is available on <a href="https://gurushots.com/chris.vanschayk/photos" target="_blank" alt="A collection of my photos">Gurushots</a>.</p>
    <span class="image-container mirror">
        <img src="./assets/img/shark-photographs.svg" />
    </span>
</div>

<div class="text-with-shark two-images">
    <span class="image-container">
        <img src="./assets/img/shark-games.svg" />
    </span>
    <p>When I’m not photographing, making or tinkering, I’m probably gaming with friends. Either online, using voice chat, or around the table with a board game. Mostly those board games go well with a nice homemade meal. As I also like to cook, our guests get the complete package when coming over.</p>
    <span class="image-container">
        <img src="./assets/img/shark-cooks.svg" />
    </span>
</div>

<div class="text-with-shark">
    <span class="image-container">
        <img src="./assets/img/shark-illustrates.svg" />
    </span>
    <p>Last but not least; I’ve been a designer for years but always had to tell people I’m not great at drawing. I always thought it was funny, a designer that can’t draw. So I set out to get better at it! One of my latest hobbies, and also a convenient skill to have professionally, is illustrating. The sharks you see around this website are my latest creation to illustrate my new found skill.</p>
</div>

<div class="banner"></div>