<div class="banner blauwdruk">
    <img src="./assets/img/blauwdruk-logo.svg" style="max-width: 50%;"/>
</div>

<h2>Blauwdruk (a design system)</h2>
<div class="text-wrapper">
    <p>The Client: <a href="https://www.politie.nl/" target="_blank" alt="Website of the Dutch Police">Dutch Police</a></p>
</div>

<div class="side-by-side">
    <span>
        <h5>The challenge</h5>
        <p>Aligning the whole organization to the same corporate identity and improving the communication between developers and UX-designers. Preventing every new project needs to reinvent the wheel for their own situation. Ensuring new projects get jump started by work already done by previous projects. Getting the end-user a consistent and recognizable UI to work with.</p>
    </span>
    <span>
        <div class="highlight">
            <h5>My role</h5>
            <p>
                <strong>UX-designer, ux-researcher, front-end developer, product owner, solution architect</strong><br />
                Currently I'm responsible for determining the priority of features, keeping in touch with teams using Blauwdruk, creating UX-specs, and building new components.
            </p>
        </div>
    </span>
</div>

<div class="text-wrapper">
    <h5>The solution</h5>
    <p>Creating a shared single point of truth for the complete Police organization. A design system from which every project can get their UI-components, design tokens, UX-ingredients and more. Setting up a team that is dedicated to bringing to life UX designs and taking into account aspects like accessibility and reusability. Building off-the-shelf components with the UX baked in so users don’t need to worry about styling and can be assured it follows the standards set by UX. Kickstarting all teams within the Police when starting or upgrading their application regardless of the used front-end framework.</p>
    
    <span class="image-container">
        <img src="./assets/img/case-study-blauwdruk/atomic-design.svg" style="max-width: 65.5rem;" />
        <span class="caption">Blauwdruk is based on atomic design, from atoms, molecules and organisms to templates and pages.</span>
    </span>
</div>

<div class="banner">
    <div class="banner-wrapper">
        <h5>Summary</h5>
        <p>Bringing Blauwdruk to life has two sides to it. 1) Creating a product, the technology, to actually build a shared library which can be maintained in the long run and is usable for many projects using a variety of techniques. 2) Convincing the organization of the potential of such a solution and aligning different projects to this solution.</p>
        <p>Building up Blauwdruk as a phenomenon has been quite the journey. It started out as a small proof of concept in a huge project to reduce the duplicate code and to give UX more leverage in proces. A dialog with feature teams to create a platform that would service them and make it easier to get insight in the UX-process. To grow into a platform that doesn’t service just one project, but potentially every single project in the organization.</p>

        <h5>End result</h5>
        <p>Blauwdruk is a showcase of pre-build UX-components. It is a team that is dedicated to high quality, reusable, shareable components. A design system that embodies the vision of UX brought to life and ready to be implemented. A single point of truth both developers and designers can refer to, which can be used to introduce new concepts to the corporate identity. A kickstart for a new project and the promise of being aligned across the whole Police organization.</p>

        <h5>Honorable mention</h5>
        <p>Building this design system has been a great success. So much so that Blauwdruk was featured on <a href="https://tweakers.net/plan/3126/designsysteem-blauwdruk-helpt-development-bij-de-politie-te-stroomlijnen.html" target="_blank" alt="Tweakers.net article">Tweakers.net</a> to showcase it's solution and technical construction. Read more at <a href="https://tweakers.net/plan/3126/designsysteem-blauwdruk-helpt-development-bij-de-politie-te-stroomlijnen.html" target="_blank" alt="Article about Blauwdruk on Tweakers.net">the article on Tweakers.net</a>. On top of that I got the chance to present Blauwdruk to visitors of the Tweakers IT summit together with my front-end partner <a href="https://www.linkedin.com/in/ryankool/" alt="Ryan Kool's Linkedin profile">Ryan</a>.</p>

        <div class="button-container">
            <button mat-flat-button select [matMenuTriggerFor]="scrollto" alt="More about this case-study">More about this</button>
            <mat-menu #scrollto="matMenu">
                <button mat-menu-item (click)="scrollToSection('next-section')">Cause and need</button>
                <button mat-menu-item (click)="scrollToSection('usingblauwdruk')">Using Blauwdruk</button>
                <button mat-menu-item (click)="scrollToSection('uxdevelopment')">Integrating UX and development</button>
                <button mat-menu-item (click)="scrollToSection('specific')">Specific vs. generic</button>
                <button mat-menu-item (click)="scrollToSection('kickstart')">Kickstart + insight</button>
            </mat-menu>
            <button mat-flat-button select [matMenuTriggerFor]="othercases" alt="View other case studies">View another case study</button>
            <mat-menu #othercases="matMenu">
                <button mat-menu-item routerLink="/dark-theme" routerLinkActive="active" alt="Dark theme">Dark theme</button>
                <button mat-menu-item routerLink="/enrollment-process" routerLinkActive="active" alt="Enrollment process">Enrollment process</button>
                <button mat-menu-item routerLink="/optimizing-input" routerLinkActive="active" alt="Optimizing input">Optimizing input</button>
            </mat-menu>
        </div>
    </div>
</div>

<a class="anchor" id="next-section"></a>
<div class="text-wrapper">
    <h3>The steps</h3>
    <h5>Cause and need</h5>
    <p>At the start of the project the team was small, it was easy to discuss the progress and to keep an overview on what everyone was delivering. Over time though it proved to be more difficult, as more people joined the project and new teams, working from a different location altogether, were added. At some point it was impossible to keep track of every part being delivered by anyone. Developers in different teams had to solve similar problems but weren't always aware of an existing solution, meaning they would all try to solve the problem in their own way.</p>
    <p>All these different solutions needed to be updated whenever there was a design/usability change. Changing multiple different implementations of similar, but different, solutions costs a lot of effort, taking a lot of valuable time that could be spent on building new functionality.</p>
    <p>In a best case scenario changing the design of, for example, a button will have to be done at one spot in the code and will change all buttons in the application accordingly. In real life this doesn’t always work as simply. Ideally the code should be centralized, something Blauwdruk does for the project.</p>
    <p>When looking at the bigger picture, a plethora of projects, all using the same corporate identity and in fact the same looking button. If we change the design of that button, all those projects need to implement the change separately. Every one of those projects will hope that it requires changing a single line of code in one particular spot.</p>
    <p>Example:<br />A design change is made to a button. The Art Director informs all UX-designers to update their design to the new iteration. Every team that has a UX-designer gets the memo and prioritises the change. Every project that changes the button will need at least one developer and probably a test-engineer to test the change. Some projects don’t have a direct link to UX and don’t get the memo.</p>
    <p>The result: A month after the change only 33% of all projects sport the new button<br />
    The actual result: Our end-users will have a fragmented user experience across different in-house products.</p>
    <span class="image-container">
        <img src="./assets/img/case-study-blauwdruk/before-bp.svg" style="max-width: 65.5rem;" />
        <span class="caption">An example of how quick design changes can be applied organization-wide without a technical design system.</span>
    </span>
</div>

<a class="anchor" id="usingblauwdruk"></a>
<div class="text-wrapper">
<h5>Using Blauwdruk</h5>
<p>The Art Director shows off their new design iteration, gorgeous, and only a small but important change. UX-designers have already tested the new design and the basic button needs a small adjustment. Blauwdruk developers look at the change, change it in Blauwdruk and release a new version. It takes one developer and a tester to make the change and all parties get a ping automatically, telling them a new version of Blauwdruk has been released.</p>
<p>Before Blauwdruk the project would be notified of a change, they would need to understand the change, prioritize the change, implement the change, test the change and be responsible for the quality and any feedback received from implementing it. Instead the project team can now accept the changes and run one regression test to see if there aren’t any unforeseen consequences and be done.</p>
<p>The result: A month after changing the button design 90% of all projects sport the new design.<br />
    The actual result: Out end-users have a consistent user experience.</p>
    <span class="image-container">
        <img src="./assets/img/case-study-blauwdruk/after-bp.svg" style="max-width: 65.5rem;" />
        <span class="caption">An example of how quick design changes can be applied organization-wide with a technical design system.</span>
    </span>
</div>

<a class="anchor" id="uxdevelopment"></a>
<h5>Integrating UX and development</h5>
<p>Blauwdruk is an effort to integrate UX with development, having a dedicated development team that is in direct contact with the UX-designers responsible for the corporate identity. It enables developers and designers to work to their full potential, combining the best of both worlds. Developers are very used to working modular, looking at the smallest reusable components and reusing those to build bigger components. UX-designers have a great understanding of the end-user and how they interact with the components. They can also look ahead and know what is coming because of information analysis and designing the bigger picture.</p>
<div class="side-by-side">
    <span class="image-container" style="max-width: 30rem;">
        <img srcset="./assets/img/case-study-blauwdruk/codebehind.jpg 1x,
                     ./assets/img/case-study-blauwdruk/codebehind@2x.jpg 2x"
             src="./assets/img/case-study-blauwdruk/codebehind.jpg" />
        <span class="caption">
            A screenshot of the first version of Blauwdruk showing the code that is used to use a specific component.
        </span>
    </span>
    <span class="image-container" style="max-width: 30rem;">
        <img srcset="./assets/img/case-study-blauwdruk/liveexample.jpg 1x,
                     ./assets/img/case-study-blauwdruk/liveexample@2x.jpg 2x"
             src="./assets/img/case-study-blauwdruk/liveexample.jpg"/>
        <span class="caption">
            A screenshot of the same component, showing the configurator to simply configure a specific component.
        </span>
    </span>
</div>

<a class="anchor" id="specific"></a>
<h5>Specific vs. generic</h5>
<p>Blauwdruk works because it looks at common numerators, it builds the base components all projects need. It also provided more specific components that are used by just a few projects. It is a struggle that comes with centralizing the creation of reusable components.</p>
<p>The idea is that every time a team that uses Blauwdruk needs a new component for their project, they will first consult with Blauwdruk to see if more projects are going to need it. If so, will they have the exact same requirements or could they potentially differ.</p>
<p>Blauwdruk will then discuss a plan of approach. For example, a first iteration of the component is built that will fulfill the requirements of one project in the short term. In the long term the component will be expanded to suit the requirements of another project. Because of getting together beforehand backwards compatibility can be sustained.</p>
<p>For very specific components that are only used once or twice, projects can opt to build their own. This way the very specific components that do not add to the bigger whole will not slow down the velocity of Blauwdruk or the team that needs to use it.</p>

<a class="anchor" id="kickstart"></a>
<div class="banner">
    <div class="banner-wrapper">
        <h5>Kickstart + insight</h5>
        <p>Blauwdruk is a backbone for existing projects but should also be a kickstart for any starting project. It does so by providing ready-to-use components with the right design and interactions baked in. It’s equipped with a configurator which allows developers and designers to not only look at a component but change it’s properties to better understand their inner workings.</p>
        <p>A new designer can use Blauwdruk to get a better understanding of all available and realized components and interactions by interacting with components in the browser.</p>
        <p>A new developer can get a quick insight in all available components and also their inner workings by changing the configuration in the browser. This also allows the developer to change the components so they match with the design a UX-designer had made.</p>
        <p>An example:</p>

        <span class="example-component-configurator">
            <span class="component">
                <button mat-flat-button class="example-button" [color]="exampleColor"><span class="icon">{{exampleIcon}}</span>{{exampleTitle}}</button>
            </span>
            <span class="knobs">
                <span class="title">Options</span>
                <mat-form-field appearance="fill">
                    <mat-label>Label</mat-label>
                    <input matInput type="text" [(ngModel)]="exampleTitle">
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Icon</mat-label>
                    <mat-select [(ngModel)]="exampleIcon">
                        <mat-option value="">None</mat-option>
                        <mat-option value="person">Person</mat-option>
                        <mat-option value="settings">Settings</mat-option>
                        <mat-option value="info">Info</mat-option>
                        <mat-option value="refresh">Refresh</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Appearance</mat-label>
                    <mat-select [(ngModel)]="exampleColor">
                        <mat-option value="">Primary</mat-option>
                        <mat-option value="accent">Accent</mat-option>
                        <mat-option value="warn">Warn</mat-option>
                    </mat-select>
                </mat-form-field>
            </span>
        </span>
        
    </div>
</div>

<more-cases currentCase="blauwdruk"></more-cases>