import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { CaseStudyBlauwdrukComponent } from './pages/case-studies/case-study-blauwdruk/case-study-blauwdruk.component';
import { CaseStudyDarkThemeComponent } from './pages/case-studies/case-study-dark-theme/case-study-dark-theme.component';
import { CaseStudyEnrollingComponent } from './pages/case-studies/case-study-enrolling/case-study-enrolling.component';
import { CaseStudyOptimizingInputComponent } from './pages/case-studies/case-study-optimizing-input/case-study-optimizing-input.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'blauwdruk', component: CaseStudyBlauwdrukComponent },
  { path: 'dark-theme', component: CaseStudyDarkThemeComponent },
  { path: 'enrollment-process', component: CaseStudyEnrollingComponent },
  { path: 'optimizing-input', component: CaseStudyOptimizingInputComponent },
  { path: 'about', component: AboutPageComponent },
  { path: '**', component: PageNotFoundComponent }, // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
