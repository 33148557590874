import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CaseStudyComponent } from './components/case-study/case-study.component';
import { TagChipComponent } from './components/tag-chip/tag-chip.component';
import { FooterComponent } from './components/footer/footer.component';
import { CaseStudyBlauwdrukComponent } from './pages/case-studies/case-study-blauwdruk/case-study-blauwdruk.component';
import { CaseStudyDarkThemeComponent } from './pages/case-studies/case-study-dark-theme/case-study-dark-theme.component';
import { CaseStudyEnrollingComponent } from './pages/case-studies/case-study-enrolling/case-study-enrolling.component';
import { HexWrapperComponent } from './components/hex-wrapper/hex-wrapper.component';
import { MoreCasesComponent } from './components/more-cases/more-cases.component';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CaseStudyOptimizingInputComponent } from './pages/case-studies/case-study-optimizing-input/case-study-optimizing-input.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    AboutPageComponent,
    CaseStudyBlauwdrukComponent,
    CaseStudyDarkThemeComponent,
    CaseStudyEnrollingComponent,
    CaseStudyOptimizingInputComponent,
    PageNotFoundComponent,
    CaseStudyComponent,
    TagChipComponent,
    FooterComponent,
    HexWrapperComponent,
    MoreCasesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatRippleModule,
    MatMenuModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
