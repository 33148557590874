<div class="banner cover">
    <div class="dark-theme"></div>
    <img src="./assets/img/case-study-dark-theme/dark-theme-cover-image.png" 
         srcset="./assets/img/case-study-dark-theme/dark-theme-cover-image.png 1x, ./assets/img/case-study-dark-theme/dark-theme-cover-image@2x.png 2x" 
         alt="Dark theme cover image">
</div>

<h2>Dark theme</h2>
<div class="text-wrapper">
    <p>The Client: <a href="https://www.politie.nl/" target="_blank" alt="Website of the Dutch Police">Dutch Police</a></p>
</div>

<div class="side-by-side">
    <span>
        <h5>The challenge</h5>
        <p>How can we read information from our glass screens on a bright day, while also avoiding unwanted attention at night/avoid impairing our night vision? When on stakeout we don’t want to risk being easily detected because of a very recognizable, visible and heavy blue glow that emits from most of our police apps.</p>
    </span>
    <span>
        <div class="highlight">
            <h5>My role</h5>
            <p>
                <strong>UX-Researcher, designer, tester, photographer</strong><br />
                I was responsible for the research, photography, report and parts of the visual designs.
            </p>
        </div>
    </span>
</div>

<div class="text-wrapper">
    <h5>The solution</h5>
    <p>By using a dark theme which is muted in colours,contrast and brightness we made a huge impact on the user experience during the night. Making sure police officers don’t get recognized as easily, their night vision isn’t impared as much and the amount of eye strain is drastically reduced, while still keeping a contrasty and well readable light theme for bright situations. How did we come to this solution, using merely a camera, tripod, car, a willing participant and a few theming concepts? Read on for the full report.</p>
    <span class="image-container">
        <img src="./assets/img/case-study-dark-theme/light-vs-dark.jpg" 
             srcset="./assets/img/case-study-dark-theme/light-vs-dark.jpg 1x, ./assets/img/case-study-dark-theme/light-vs-dark@2x.jpg 2x" />
        <span class="caption">Regular (light) theme on the left vs. the proposed dark theme on the right. Photos taken with the same settings.</span>
    </span>
</div>

<div class="banner">
    <div class="banner-wrapper">
        <h5>Summary</h5>
        <p>The road to a solution can be divided in a number of steps. Firstly, we needed to determine what data already existed on the subject. Having done the research we still had some questions which merited a test of our own. For this test we needed a baseline, for which we took an existing app and the light theme of our own app, and a number of concepts for a dark theme.</p>
        <p>With the baseline and concepts a comparison was made in the dark, showing the differences in light emitted by the screen. The results of this setup were then verified in a second test using the same baseline and concepts.</p>
        <p>A third test was done to determine if the legibility of the content was correct on all of the concepts. Together with the scientific findings regarding the cones and rods in the human eye and their influence on night vision a conclusion was written.</p>

        <h5>Conclusion</h5>
        <p>Based on the research advice was given about the use and benefits of using a dark theme. This has resulted in the adoption of a dark theme, which was prioritized and will be implemented during the second phase of the build process. Users were particularly enthusiastic and the report has been shared among all UX-designers in the organization</p>

        <div class="button-container">
            <button mat-flat-button select [matMenuTriggerFor]="scrollto" alt="More about this case-study">More about this</button>
            <mat-menu #scrollto="matMenu">
                <button mat-menu-item (click)="scrollToSection('next-section')">Starting point</button>
                <button mat-menu-item (click)="scrollToSection('deskresearch')">Desk research</button>
                <button mat-menu-item (click)="scrollToSection('firstsetup')">First test setup</button>
                <button mat-menu-item (click)="scrollToSection('secondsetup')">Second test setup</button>
                <button mat-menu-item (click)="scrollToSection('legibility')">Legibility</button>
                <button mat-menu-item (click)="scrollToSection('science')">Science</button>
                <button mat-menu-item (click)="scrollToSection('conclusion')">Conclusion</button>
            </mat-menu>
            <button mat-flat-button select [matMenuTriggerFor]="othercases" alt="View other case studies">View another case study</button>
            <mat-menu #othercases="matMenu">
                <button mat-menu-item routerLink="/blauwdruk" routerLinkActive="active" alt="Blauwdruk">Blauwdruk</button>
                <button mat-menu-item routerLink="/enrollment-process" routerLinkActive="active" alt="Enrollment process">Enrollment process</button>
                <button mat-menu-item routerLink="/optimizing-input" routerLinkActive="active" alt="Optimizing input">Optimizing input</button>
            </mat-menu>
        </div>
    </div>
</div>
<a class="anchor" id="next-section"></a>
<h3>The steps</h3>
<h5>Starting point</h5>
<p>When a police app (styled heavily Police blue) is used, an officer would stand out and could be noticed by the specific blue shade the screen emits. We needed a solution to facilitate these situations and to optimize the user experience. Another problem we observed was the loss of night vision due to the heavy use of pure white in the regular theme.</p>

<a class="anchor" id="deskresearch"></a>
<div class="text-with-image">
    <h5>Desk research</h5>
    <span class="image-container">
        <img src="./assets/img/case-study-dark-theme/starting-point.jpg"
             srcset="./assets/img/case-study-dark-theme/starting-point.jpg 1x,
                     ./assets/img/case-study-dark-theme/starting-point@2x.jpg 2x" 
             style="max-width: 27.625rem;"/>
        <span class="caption">The effect the original design has.</span>
    </span>
    <p>The first step was to do some desk research, find literature about dark themes and their benefits. One of the sources was Google’s Material Design which devoted a whole page to the subject, explaining the use of muted colours and lowered contrast. This gave us a first direction but also presented us with a new problem, brand identity and recognizability. For recognizability in the light theme we made use of specific typography, icons, and primary and secondary colours. When these colours would get muted or not shown because of dark theme best practices this could be a potential problem for our users.</p>
    <p>From all the screens a user could possibly see in the app we took a bright one, this screen we used as a base for making a number of dark-mode concepts. These concepts we would show to a number of users and ask them what app they thought they were looking at. A number of concepts were still recognizable due to the usage of the primary or accent colour. Eventually we picked four of the concepts, the most used app and one example of the same screen sporting the light theme.</p>
</div>

<a class="anchor" id="firstsetup"></a>
<h5>The first test setup</h5>
<p>To figure out what the effect of the different concepts is I asked a participant to sit in her car at night. I positioned a camera next to her car and put in the right settings to get a proper exposure. After taking a baseline photo I asked her to put the screen on her phone and sit still for a moment while I took another photo, keeping all the settings of the camera the same for every exposure. We repeated this for every one of the screens we picked. Here are the results with the corresponding screen next to the photo.</p>
<span class="image-container">
    <img srcset="./assets/img/case-study-dark-theme/side-by-side.jpg 1x,
                 ./assets/img/case-study-dark-theme/side-by-side@2x.jpg 2x"
             src="./assets/img/case-study-dark-theme/side-by-side.jpg" />
    <span class="caption">The different designs from left to right; original, new regular, saturated blue, unsaturated yellow, saturated blue, unsaturated yellow.</span>
</span>
<p>At first glance it is obvious that any of the dark theme concepts are better than either the most used (blue) app and the current light theme. We can also depict a difference between the warmer tones and the cooler tones, where the cooler tones are more muted and light up our subject the least. When comparing the two cooler screens we can also depict that the one with less saturation is more neutral and less ambient. Based on these photos alone we could already pick a candidate for the dark theme.</p>
<p>To make sure there is no bias from myself I set up a small survey for my colleagues. In this survey I would only show the taken photo and show different comparisons to make a fair assessment of which screen had the best effect ons a subject in a car in the dark. Based on this survey the cooler less saturated screen was picked almost unanimously.</p>

<a class="anchor" id="secondsetup"></a>
<h5>Second test setup & verification</h5>
<p>To further test the effect and strength of the light emitted from the screen I set up a second test environment. In this setup I placed the phone’s screen perpendicular to a meter on the floor and placed the camera close to it on a tripod. This way the camera could see the light emitted from the screen and by looking at the meter a distance could be determined.</p>
<span class="image-container">
    <img srcset="./assets/img/case-study-dark-theme/photo-comparison.jpg 1x,
                 ./assets/img/case-study-dark-theme/photo-comparison@2x.jpg 2x"
             src="./assets/img/case-study-dark-theme/photo-comparison.jpg" />
    <span class="caption">Side-by-side comparison of the designs.</span>
</span>
<p>By turning off the light and making sure the room was pitch black a number of exposures were made with the following results. This time I made two runs. Every screen was put on screen once with the phone’s night-mode disabled (as was done for the first test) and once with the night-mode enabled. The difference was almost night and day (pun intended). The phone’s night-mode darkens the screen even farther and mutes the colours on screen that little bit more. Although the differences were a lot smaller, they are still visible. The dark themes still do a far better job at reducing the amount of light emitted than the light theme. The results of this test validate what we’ve seen from the first test, but don’t show what the user actually sees on their screen. It could be quite possible that all these changes have an adverse effect on the legibility of the content.</p>
<span class="image-container">
    <img srcset="./assets/img/case-study-dark-theme/test-2-side-by-side.jpg 1x,
                 ./assets/img/case-study-dark-theme/test-2-side-by-side@2x.jpg 2x"
             src="./assets/img/case-study-dark-theme/test-2-side-by-side.jpg" />
    <span class="caption">Side-by-side comparison of the distance the light travels.</span>
</span>

<a class="anchor" id="legibility"></a>
<div class="text-with-image">
    <h5>Third test legibility</h5>
    <span class="image-container">
        <img srcset="./assets/img/case-study-dark-theme/test-3-setup.jpg 1x,
                     ./assets/img/case-study-dark-theme/test-3-setup@2x.jpg 2x"
             src="./assets/img/case-study-dark-theme/test-3-setup.jpg" />
        <span class="caption">The test-setup using a camera on a tripod to <br />
             picture the different screens.</span>
    </span>
    <p>For this last test a screen capture was made using a camera aimed at the screen. This would show the effect of the different themes and modes we had at our disposal. One by one all of the picked screens would be presented on the phone, once with and once without the phone’s night mode enabled.</p>
    <p>Of course the human eye sees pixels on a screen differently compared to a bayer sensor in a camera. These tests had to be confirmed by a human subject, more than one to be sure. But as a baseline, this test did show the impact on contrast. The assumption is that the dynamic range of the human eye is far superior to a digital camera which effectively means a human would see a clearer and better image compared to the test shots.</p>
    <p>Again the differences are made apparent. Using a dark theme helps with reducing the contrast between the screen and the outside world, and enabling the phone’s night mode adds to this effect in a big way.</p>
    <p>To show the effect between the phone’s night-mode on and off. The left-half of the images are the ones with the phone’s night-mode disabled.</p>
</div>

<div class="side-by-side">
    <span class="image-container" style="max-width: 24.875rem;">
        <img srcset="./assets/img/case-study-dark-theme/test-3-overview.jpg 1x,
                     ./assets/img/case-study-dark-theme/test-3-overview@2x.jpg 2x"
             src="./assets/img/case-study-dark-theme/test-3-overview.jpg" />
        <span class="caption">
            Comparison of the contrast and light emitted from the screens.
        </span>
    </span>
    <span class="image-container" style="max-width: 23.75rem;">
        <img srcset="./assets/img/case-study-dark-theme/test-3-comparison.jpg 1x,
                     ./assets/img/case-study-dark-theme/test-3-comparison@2x.jpg 2x"
             src="./assets/img/case-study-dark-theme/test-3-comparison.jpg" />
        <span class="caption">
            Comparison of all designs with and without the phones night-mode turned on.
        </span>
    </span>
</div>

<a class="anchor" id="science"></a>
<h5>The science of rods and cones</h5>
<p>The human eye works based on rods and cones. Cones are sensitive to the amount of light present, a kind of black and white view. Rods are sensitive to the presence of certain colours (10% blue, 30% green and 60% red). The rods are 1.000x more sensitive to light than cones, but take significantly longer to readjust to lower light after being exposed to a bright light source. Adjusting back to darkness takes about 30 minutes on average.</p>
<p>A reason to look at the science of eyes is to see if it is better to use a specific colour to make sure the usage of the app does not impede the night vision of our users. We don’t want to blind our users when they need to be ready to act at any time.</p>
<p>The conclusion is that all colours have basically the same adjustment time after exposure. Even though common belief is that red would be different (think about red light being used in dark rooms of photographers).</p>

<a class="anchor" id="conclusion"></a>
<div class="banner">
    <div class="banner-wrapper">
        <h5>Conclusion of research</h5>
        <p>Taking all the above into account the benefit of using a dark theme is evident. It reduces the blue glow on officers, making them stand out less. It also reduces the contrast between the surroundings and the relatively bright screen. Reducing the amount of light being emitted from the screens prevents the sensitive parts in our eyes from being over exposed and therefore reduces the loss of night vision. It also suggests that legibility isn’t impared by the use of a dark mode. If a phone features a night-mode it is advised to enable it to improve the benefits of the dark theme even more.</p>
        <p>Colours used should ideally be the same hue as the primary (or accent) colour of your light theme, but less saturated. The grays for background should be devoid of colour (pure grayscale) to ensure enough visual difference between accented components on screen and to reduce light emitted. The amount of coloured surfaces on the screen should be reduced to a minimum as these will emit more light than grayscale surfaces. Using absolute white and black should be avoided as these will have too big a contrast in context of the dark theme. Absolute blacks behind grays can be applied to reduce light emitted though.</p>
    </div>
</div>

<more-cases  currentCase="dark-theme"></more-cases>