import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'more-cases',
  templateUrl: './more-cases.component.html',
  styleUrls: ['./more-cases.component.scss']
})
export class MoreCasesComponent implements OnInit  {

  @Input() currentCase!: 'blauwdruk' | 'dark-theme' | 'enrolling' | 'optimizing-input';

  showBlauwdruk = false;
  showDarkTheme = false;
  showEnrolling = false;
  showOptimizingInput = false;

  ngOnInit(): void {
    
    if (this.currentCase === 'blauwdruk') {
      this.showDarkTheme = true;
      this.showEnrolling = true;
      this.showOptimizingInput = true;
    }
    if (this.currentCase === 'dark-theme') {
      this.showBlauwdruk = true;
      this.showEnrolling = true;
      this.showOptimizingInput = true;
    }
    if (this.currentCase === 'enrolling') {
      this.showBlauwdruk = true;
      this.showDarkTheme = true;
      this.showOptimizingInput = true;
    }
    if (this.currentCase === 'optimizing-input') {
      this.showBlauwdruk = true;
      this.showDarkTheme = true;
      this.showEnrolling = true;
    }
  }  
}
