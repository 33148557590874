import { Component } from '@angular/core';

@Component({
  selector: 'tag-chip',
  templateUrl: './tag-chip.component.html',
  styleUrls: ['./tag-chip.component.scss']
})
export class TagChipComponent {

}
