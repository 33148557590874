import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'case-study',
  templateUrl: './case-study.component.html',
  styleUrls: ['./case-study.component.scss']
})
export class CaseStudyComponent implements OnInit {

  @Input() subject!: string;
  @Input() buttonLabel!: string;
  @Input() imageUrl!: string;
  @Input() tags?: string[];
  @Input() coverImage = false;
  navigationParameter: string | undefined;

  ngOnInit(): void {
    this.navigationParameter = this.subject.replace(/\s+/g, '-').toLowerCase();
  }  
}