import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-case-study-blauwdruk',
  templateUrl: './case-study-blauwdruk.component.html',
  styleUrls: ['./case-study-blauwdruk.component.scss']
})
export class CaseStudyBlauwdrukComponent {

  exampleIcon: string = 'refresh';
  exampleIsExtended: boolean = true;
  exampleTitle: string = 'Refresh';
  exampleColor: string = '';

  constructor(private viewportScroller: ViewportScroller) {
  }

  public scrollToSection(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
