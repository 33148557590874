<nav>
  <div class="nav-wrapper">
    <div class="progress-bar" [style.width]="progress"></div>
    <span>
    <button routerLink="/" (click)="scrollToTop()" routerLinkActive="active" class="home-button" matRipple matRippleColor="rgba(255,255,255,.24)">
      <img src="./assets/img/DieLogoDark.svg" alt="Die logo - Chris van Schayk" class="home-button" />
      <img src="./assets/img/DieLogoLight.svg" alt="Die logo - Chris van Schayk" class="home-button" />
    </button>
    <ul>
      <li>
        <button mat-button routerLink="/" routerLinkActive="active">Home</button>
      </li>
      <li>
        <button mat-button select [matMenuTriggerFor]="cases" alt="Overview case studies">Work</button>
        <mat-menu #cases="matMenu">
          <button mat-menu-item routerLink="/blauwdruk" routerLinkActive="active" alt="Blauwdruk">Blauwdruk</button>
          <button mat-menu-item routerLink="/dark-theme" routerLinkActive="active" alt="Dark theme">Dark theme</button>
          <button mat-menu-item routerLink="/enrollment-process" routerLinkActive="active" alt="Enrollment process">Enrollment process</button>
          <button mat-menu-item routerLink="/optimizing-input" routerLinkActive="active" alt="Optimizing input">Optimizing input</button>
        </mat-menu>
      </li>
      <li>
        <button mat-button routerLink="/about" routerLinkActive="active">About</button>
      </li>
    </ul>
  </span>
  </div>
</nav>
<main class="content" #container>
  <hex-wrapper></hex-wrapper>
  <div class="main-wrapper">
    <router-outlet></router-outlet>
  </div>
</main>
<footer [showFooterShark]="showFooterShark"></footer>