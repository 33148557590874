import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  @Input() progress = '0%';
  showFooterShark = false;

  title = 'portfolio2021';
  private eventSub!: Subscription;

  ngOnInit() {
    this.eventSub = fromEvent(window, 'scroll').pipe(
      tap(event => this.scroll(event))
    ).subscribe();
  }

  scroll(event: any) {
    let windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    let sharkTriggerPosition = windowHeight - 250;
    let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
    let percentage = (scrollPosition / windowHeight) * 100;

    this.showFooterShark = (scrollPosition > sharkTriggerPosition);
    this.progress = percentage + '%';
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnDestroy() {
    this.eventSub.unsubscribe(); // don't forget to unsubscribe
  }
}