import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-case-study-enrolling',
  templateUrl: './case-study-enrolling.component.html',
  styleUrls: ['./case-study-enrolling.component.scss']
})
export class CaseStudyEnrollingComponent {

  constructor(private viewportScroller: ViewportScroller) {
  }

  public scrollToSection(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
