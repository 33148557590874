<h3>More cases</h3>
<p>Here is a selection of some of my other projects, feel free to take a look</p>
<div class="other-cases">
    <case-study *ngIf="showBlauwdruk"
                subject="Blauwdruk"
                imageUrl="./assets/img/blauwdruk-logo.svg"
                [tags]="['Visual design','Research','Information design','Development','Structure','Facilitation','Context-aware design']"
                buttonLabel="View case study">
        How to build a design system? Blauwdruk is the new technical design system of the Dutch Police, based on <a href="https://storybook.js.org/" target="_blank" rel="noreferrer">Storybook</a>.
    </case-study>

    <case-study *ngIf="showDarkTheme"
                subject="Dark theme"
                imageUrl="./assets/img/dark-vs-light-cover-picture-small.jpg"
                [tags]="['Research', 'Context-aware design', 'Testing']"
                [coverImage]=true
                buttonLabel="View research">
        Apps on mobile devices give users the ability to use it anytime and anywhere, including at night. This presents Police officers with flexibility but also exposes them during stakeouts for example.
    </case-study>

    <case-study *ngIf="showEnrolling"
                subject="Enrollment process"
                imageUrl="./assets/img/enrolling.jpg"
                [tags]="['Customer journey', 'User research', 'Information design', 'Visual design']"
                [coverImage]=true
                buttonLabel="View case study">
        SamSam offers entrepreneurs an alternative to the often costly disability insurance. They asked me to take a look at how their enrollment process could be optimized to ensure users can enroll effortlessly.
    </case-study>

    <case-study *ngIf="showOptimizingInput"
                subject="Optimizing input"
                imageUrl="./assets/img/gtbps-cover.jpg"
                [tags]="['Customer journey', 'User research', 'Usability testing', 'Interaction design', 'Visual design', 'App development']"
                [coverImage]=true
                buttonLabel="View case study">
        When you need to navigate to specific locations along the national highways you can't use an address and inputting a GPS-coordinate is not an easy task. I looked at an easy way for road inspectors to input these specific locations.
    </case-study>
</div>