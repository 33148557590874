<div class="hex blue">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 71">
        <defs/>
        <path d="M56.5 10.7l-14-8.1a19 19 0 00-19 0l-14 8.1A19.3 19.3 0 000 27.4v16.2a19.3 19.3 0 009.5 16.7l14 8.1a19 19 0 0019 0l14-8.1A19.3 19.3 0 0066 43.6V27.4a19.3 19.3 0 00-9.5-16.7z" data-name="hexagon"/>
      </svg>
</div>
<div class="hex coral">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 71">
        <defs/>
        <path d="M56.5 10.7l-14-8.1a19 19 0 00-19 0l-14 8.1A19.3 19.3 0 000 27.4v16.2a19.3 19.3 0 009.5 16.7l14 8.1a19 19 0 0019 0l14-8.1A19.3 19.3 0 0066 43.6V27.4a19.3 19.3 0 00-9.5-16.7z" data-name="hexagon"/>
    </svg>
</div>
<div class="footer-text" [class.show]="showFooterShark">
    <img class="shark-says-hi" [class.show]="showFooterShark" src="./assets/img/shark-says-hi.svg" alt="shark says hi">
    <div class="text-bubble-wrapper">
        <div class="text-bubble">
            <span>Say Hi</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233.5 107.8">
                <defs/>
                <path d="M117 8.2C179 8.2 229.5 30.5 229.5 58c0 12.7-10.8 24.3-28.7 33.2l32.4 16.2c-23-1.8-50-9.1-50-9.1-18.3 6-41.3 9.5-66.2 9.5C54.8 107.8 4.4 85.5 4.4 58S54.8 8.2 117 8.2z" opacity=".2"/>
                <g fill="#f6f5f5">
                <path d="M223.3 103.9l-7.5-1.8a243 243 0 01-36.2-13.8l-.7-.4-.8.2c-9.2 3-19.4 5.3-30.3 7a244 244 0 01-97.6-6c-5.9-1.7-11.4-3.7-16.4-6-5-2.2-9.6-4.6-13.5-7.2-4-2.6-7.3-5.3-10-8.1a26.1 26.1 0 01-8.3-18c0-6.2 2.8-12.3 8.3-18 2.7-2.9 6-5.6 10-8.2 4-2.6 8.5-5 13.5-7.2s10.5-4.2 16.4-6A237 237 0 01112.5 2c15.1 0 29.7 1.3 43.5 3.9 6.6 1.2 13 2.7 18.9 4.5 5.9 1.8 11.4 3.8 16.4 6 5 2.2 9.5 4.6 13.5 7.2 3.9 2.6 7.3 5.3 10 8.2a26.1 26.1 0 018.3 18c0 5.7-2.4 11.3-7.2 16.7a66 66 0 01-20.4 14.7l-3 1.4 2.7 2a642.8 642.8 0 0028.1 19.3z"/>
                <path fill="#00334e" d="M112.5 4a235 235 0 00-43 3.8c-6.6 1.3-12.9 2.8-18.7 4.5-5.8 1.8-11.3 3.8-16.2 6a92.3 92.3 0 00-13.2 7c-3.8 2.5-7 5.1-9.7 7.9C6.6 38.5 4 44.2 4 49.8c0 5.6 2.6 11.2 7.7 16.6 2.6 2.7 5.9 5.4 9.7 7.8 3.9 2.6 8.3 5 13.2 7.1 5 2.2 10.4 4.2 16.2 6a235 235 0 0096.8 5.8c10.7-1.6 20.8-4 30-6.9l1.5-.5 1.4.7A240.3 240.3 0 00212 99a620.2 620.2 0 01-18-12.7l-5.3-4 6-2.8c4.2-2.1 8-4.4 11.4-6.8a50 50 0 008.3-7.4c4.4-5 6.7-10.2 6.7-15.4 0-5.7-2.6-11.3-7.8-16.6a55.2 55.2 0 00-9.6-8 92.3 92.3 0 00-13.2-7 183.5 183.5 0 00-34.9-10.4 235 235 0 00-43-3.8m0-4C174.5 0 225 22.3 225 49.8c0 12.7-10.8 24.3-28.7 33.2 0 0 25.4 18.7 37.1 24.4-23-1.7-54.8-17.4-54.8-17.4-18.3 6-41.3 9.5-66.2 9.5C50.4 99.5 0 77.3 0 49.8 0 22.3 50.4 0 112.5 0z"/>
                </g>
            </svg>
        </div>
    </div>
    <a href="https://nl.linkedin.com/in/chris-van-schayk-3a5b7422" target="_blank" alt="LinkedIn profiel Chris van Schayk" rel="noreferrer" class="linkedin-button">
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.97 34.19">
                <defs/>
                <path fill="var(--linkedin-blue)" d="M0 2.45A2.48 2.48 0 012.51 0h28.95a2.48 2.48 0 012.51 2.45v29.29a2.48 2.48 0 01-2.5 2.45H2.5A2.48 2.48 0 010 31.74V2.44z" data-name="Path 1810"/>
                <path fill="var(--linkedin-white)" d="M10.3 28.62V13.18H5.17v15.44h5.13zM7.73 11.07a2.86 2.86 0 10-.03 0h.03zm5.4 17.55h5.13V20a3.52 3.52 0 01.17-1.25 2.8 2.8 0 012.64-1.88c1.85 0 2.6 1.42 2.6 3.49v8.26h5.13v-8.85c0-4.74-2.53-6.95-5.91-6.95a5.11 5.11 0 00-4.66 2.6h.04v-2.24h-5.14c.07 1.45 0 15.44 0 15.44z" data-name="Path 1813"/>
            </svg>
        </div>
    </a>
    <a href="mailto:chris.vanschayk@the-future-group.com?subject=Hi" target="_blank" alt="Mail me"> 
        chris.vanschayk@<br/>the-future-group.com
    </a>
</div>
<div class="hex broken-white">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 71">
        <defs/>
        <path d="M56.5 10.7l-14-8.1a19 19 0 00-19 0l-14 8.1A19.3 19.3 0 000 27.4v16.2a19.3 19.3 0 009.5 16.7l14 8.1a19 19 0 0019 0l14-8.1A19.3 19.3 0 0066 43.6V27.4a19.3 19.3 0 00-9.5-16.7z" data-name="hexagon"/>
      </svg>
</div>